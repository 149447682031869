import { InfiniteScroll } from 'tw-elements';
// Get Any Element html
const feedContainer = document.querySelector('#feedNews');
const spinner = document.querySelector('#spinner');
let isFetching = false;
let isLastPage = false;

const loadComponentArticle = (url) => {
    if (isFetching) {
        return;
    }

    if (isLastPage) {
        spinner.classList.replace('inline-block', 'hidden');
        return;
    }
    var pageActive = parseInt(feedContainer.dataset.page);
    pageActive++;
    // Spinner Visible
    spinner.classList.replace('hidden', 'inline-block');
    // cek pada url apakah ada parameter q atau tidak
    let LINK = url;
    if (url.includes('?')) {
        LINK = url + '&page=' + pageActive;
    } else {
        LINK = url + '?page=' + pageActive;
    }

    isFetching = true;

    fetch(LINK)
        .then(response => response.json())
        .then(html => {
            if (feedContainer.dataset.page == html.lastPage) {
                isLastPage = true;
                return;
            }
            // window.removeEventListener('complete.te.infiniteScroll', loadComponentArticle);
            // Spinner Hidden
            spinner.classList.replace('inline-block', 'hidden');
            // Append html
            feedContainer.insertAdjacentHTML('beforeend', html.html);
            // Update dataset
            feedContainer.setAttribute('data-page', pageActive);
        })
        .catch(function (err) {
            console.error(err);
        })
        .finally(() => {
            isFetching = false;
        });

        return isLastPage;
}
new InfiniteScroll(window);
export default loadComponentArticle;
